export const extractYupErrors = ({ inner = [] }) =>
  inner.reduce((acc, { path: errField, message }) => {
    acc[errField] = message;

    return acc;
  }, {});

const getRandom = (min, max) => Math.round(min - 0.5 + Math.random() * (max - min + 1));

export const shuffleList = (list) => {
  if (!list?.length) return list;

  const temp = [...list];
  const result = [];

  for (; temp.length; ) {
    const randomIndex = getRandom(0, temp.length - 1);

    result.push(temp[randomIndex]);
    temp.splice(randomIndex, 1);
  }

  return result;
};

const formatDate = new Intl.DateTimeFormat('de', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
});

export const parseDate = (date) => (date ? formatDate.format(new Date(date)) : '');
