import styled from 'styled-components';

export const Main = styled.main`
  align-self: center;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${({ $withHeader }) => ($withHeader ? 1060 : 1280)}px;
  max-height: 100%;
  padding: ${({ $isLoggedIn, $isHomePage, $withHeader }) =>
    (!$isLoggedIn && '36px 15px 20px') ||
    ($isHomePage && '36px 15px 30px 215px') ||
    ($withHeader ? '36px 15px 20px 60px' : '100px 15px 30px 60px')};
`;
