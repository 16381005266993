import React from 'react';
import { Link, useMatch } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useLoggedIn } from 'store/session';
import Support from 'components/Support';
import { ReactComponent as LogoIcon } from 'assets/svg-icons/logo.svg';

import { Container, Wrapper, Content } from './styles';

const Header = ({ isFixed = false, maxWidth = 1060, className, children = null }) => {
  const isLoggedIn = useLoggedIn();
  const isHomePage = useMatch('/');

  return (
    <Container $isFixed={isFixed} className={className}>
      <Wrapper $maxWidth={maxWidth} $isLoggedIn={isLoggedIn} $isHomePage={isHomePage}>
        {!isLoggedIn && (
          <Link to="/">
            <LogoIcon />
          </Link>
        )}
        <Content>{children}</Content>
        <Support />
      </Wrapper>
    </Container>
  );
};

Header.propTypes = {
  isFixed: PropTypes.bool,
  maxWidth: PropTypes.number,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Header;
