import styled from 'styled-components';
import { motion } from 'framer-motion';

import Btn from 'components/Button';

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  min-width: 36px;
  height: 36px;
`;

export const Container = styled.div`
  position: relative;
  &:before {
    content: '';
    display: ${({ $isOpened }) => ($isOpened ? 'block' : 'none')};
    position: absolute;
    inset: calc(100% - 3px) 11px auto auto;
    width: 15px;
    height: 15px;
    transform: rotate(45deg);
    box-shadow: var(--shadow);
  }
  &:after {
    content: '';
    display: ${({ $isOpened }) => ($isOpened ? 'block' : 'none')};
    position: absolute;
    inset: calc(100% - 3px) 11px auto auto;
    background-color: var(--color-white);
    width: 15px;
    height: 15px;
    transform: rotate(45deg);
    z-index: 100;
  }
`;

export const Wrapper = styled(motion.div).attrs(({ $isOpened }) => ({
  animate: { height: $isOpened ? 'auto' : 0 },
  transition: { duration: $isOpened ? 0.3 : 0 },
  initial: false,
}))`
  position: absolute;
  inset: calc(100% + 4px) -6px auto auto;
  background-color: var(--color-white);
  box-shadow: var(--shadow);
  overflow: hidden;
  z-index: 100;
`;

export const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  min-width: 365px;
  padding: 16px;
`;

export const Text = styled.p`
  font: var(--font16);
  & > span {
    display: block;
    font: var(--font16B);
    margin-bottom: 12px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  position: relative;
  min-width: 365px;
  padding: 16px;
`;

export const CloseBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  inset: 10px 12px auto auto;
  color: var(--color-black);
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export const Submit = styled(Btn)`
  margin-top: 12px;
`;
