import { lazy } from 'react';
import { matchPath } from 'react-router-dom';
import { cond, pipe, map, propOr, flatten, T } from 'ramda';

import { INTRODUCTION } from 'store/tasks';
import { WRITING } from 'store/writings';

import Layout from './Layout';
import SkyLayout from './SkyLayout';

export const ROUTES = [
  {
    key: 'index',
    layout: Layout,
    items: [
      {
        path: '/',
        component: lazy(() => import('pages/Home')),
        isPrivate: true,
        meta: {
          title: 'Home',
          description: 'Description',
        },
      },
      {
        path: INTRODUCTION,
        component: lazy(() => import('pages/Introduction')),
        meta: {
          title: 'Introduction',
          description: 'Description',
        },
      },
      {
        path: [INTRODUCTION, ':task'].join('/'),
        component: lazy(() => import('pages/Introduction')),
        meta: {
          title: 'Introduction',
          description: 'Description',
        },
      },
      {
        path: [INTRODUCTION, ':task', ':index'].join('/'),
        component: lazy(() => import('pages/Introduction')),
        meta: {
          title: 'Introduction',
          description: 'Description',
        },
      },
      {
        path: WRITING,
        component: lazy(() => import('pages/Writings')),
        meta: {
          title: 'Writing',
          description: 'Description',
        },
      },
      {
        path: [WRITING, ':task'].join('/'),
        component: lazy(() => import('pages/Writing')),
        meta: {
          title: 'Writing',
          description: 'Description',
        },
      },
      {
        path: '/profile',
        component: lazy(() => import('pages/Profile')),
        isPrivate: true,
        meta: {
          title: 'Profile',
          description: 'Description',
        },
      },
    ],
  },
  {
    key: 'external',
    layout: SkyLayout,
    items: [
      {
        path: '/login',
        component: lazy(() => import('pages/Login')),
        meta: {
          title: 'Login',
          withoutIndexing: true,
        },
      },
    ],
  },
];

const allRoutes = pipe(
  map(
    pipe(
      propOr([], 'items'),
      map((parent) => [
        parent,
        ...(parent?.items
          ? parent.items.map((item) => ({ ...item, path: [parent.path, parent.path === '/' ? '' : '/', item.path].join('') }))
          : []),
      ])
    )
  ),
  flatten
)(ROUTES);

export const routesMapping = cond([
  ...map((route) => [(currentPath) => matchPath(route.path, currentPath), () => route], allRoutes),
  [T, () => ({})],
]);
