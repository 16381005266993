import { createName } from 'store/utils';

import { FEATURE_NAME } from './consts';

const getName = createName(FEATURE_NAME);

export const AUTHENTICATE = getName('AUTHENTICATE');
export const UPDATE_USER = getName('UPDATE_USER');
export const LOGOUT = getName('LOGOUT');

export const GET_CODE = getName('GET_CODE');
export const LOGIN = getName('LOGIN');
export const RESTORE = getName('RESTORE');
export const SAVE_USER = getName('SAVE_USER');
export const SET_PROFILE = getName('SET_PROFILE');
export const SEND_BUG_REPORT = getName('SEND_BUG_REPORT');
