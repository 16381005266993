import React, { useRef, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { omit } from 'ramda';

import { useLoggedIn, useAlerts, useSessionActions, useUser, EMAIL, MESSAGE, PAGE_URL } from 'store/session';
import { useAsyncState } from 'utils/hooks';
import { extractYupErrors } from 'utils';
import TextInput from 'components/TextInput';
import Textarea from 'components/Textarea';
import Message from 'components/Message';
import { ReactComponent as SupportIcon } from 'assets/svg-icons/help.svg';
import { ReactComponent as CloseIcon } from 'assets/svg-icons/close2.svg';
import { ReactComponent as SuccessIcon } from 'assets/svg-icons/success.svg';

import { validationSchema } from './utils';
import { Button, Container, Wrapper, TextWrap, Text, Form, CloseBtn, Submit } from './styles';

const Support = () => {
  const ref = useRef();
  const { t } = useTranslation('common');
  const { pathname } = useLocation();
  const isLoggedIn = useLoggedIn();
  const email = useUser(EMAIL);
  const [isOpened, setOpened] = useState(false);
  const [errors, setErrors] = useAsyncState({});
  const { sendBugReport } = useSessionActions();
  const { action, loading, success, error, resetAlerts } = useAlerts(sendBugReport);
  const onToggle = useCallback(() => {
    setOpened(($) => !$);
    resetAlerts();
    setErrors({});
  }, [resetAlerts, setErrors]);
  const onClose = useCallback(() => {
    setOpened(false);
    resetAlerts();
    setErrors({});
  }, [resetAlerts, setErrors]);

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault(e);

      try {
        action(
          await validationSchema.validate(
            {
              [EMAIL]: isLoggedIn ? email : e.target[EMAIL].value,
              [MESSAGE]: e.target[MESSAGE].value,
              [PAGE_URL]: `${process.env.REACT_APP_HOST}${pathname}`,
            },
            { abortEarly: false }
          )
        );
      } catch (err) {
        setErrors(extractYupErrors(err));
      }
    },
    [action, isLoggedIn, email, pathname, setErrors]
  );

  const onChange = useCallback(
    ({ target }) => {
      if (errors[target.name]) setErrors(($) => omit([target.name], $));

      resetAlerts();
    },
    [errors, resetAlerts, setErrors]
  );

  useEffect(() => {
    if (!isOpened) return () => {};

    const cb = ({ target }) => {
      if (!ref.current.contains(target)) onClose();
    };

    window.document.body.addEventListener('click', cb);

    return () => {
      window.document.body.removeEventListener('click', cb);
    };
  }, [isOpened, onClose]);

  return (
    <Container ref={ref} $isOpened={isOpened}>
      <Button type="button" onClick={onToggle}>
        <SupportIcon />
      </Button>
      <Wrapper $isOpened={isOpened} onChange={onChange}>
        {success ? (
          <TextWrap>
            <CloseBtn type="button" onClick={onClose}>
              <CloseIcon />
            </CloseBtn>
            <SuccessIcon />
            <Text>
              <span>{t('Your feedback has been successfully sent')}</span>
              {t('We will process your feedback')}
            </Text>
          </TextWrap>
        ) : (
          <Form onSubmit={onSubmit}>
            <CloseBtn type="button" onClick={onClose}>
              <CloseIcon />
            </CloseBtn>
            <Textarea
              name={MESSAGE}
              label={t('Drop us a message')}
              placeholder={t('Give us feedback on any problems you have encountered')}
              error={errors[MESSAGE] && t(errors[MESSAGE])}
              disabled={loading}
            />
            {!isLoggedIn && (
              <TextInput
                name={EMAIL}
                type="text"
                label={t('Enter Email')}
                placeholder={t('Email Address')}
                error={errors[EMAIL] && t(errors[EMAIL])}
                disabled={loading}
              />
            )}
            <div>
              <Submit type="submit" size="S" isLoading={loading}>
                {t('Send')}
              </Submit>
              {error && <Message error>{error}</Message>}
            </div>
          </Form>
        )}
      </Wrapper>
    </Container>
  );
};

export default Support;
