import { createAction } from 'redux-actions';

import { createAlertAction } from 'store/alerts';

import { AUTHENTICATE, LOGOUT, UPDATE_USER, GET_CODE, LOGIN, RESTORE, SAVE_USER, SET_PROFILE, SEND_BUG_REPORT } from './types';

export const authenticate = createAction(AUTHENTICATE);
export const logout = createAction(LOGOUT);
export const updateUser = createAction(UPDATE_USER);

export const getCode = createAlertAction(GET_CODE);
export const login = createAlertAction(LOGIN);
export const restore = createAlertAction(RESTORE);
export const saveUser = createAlertAction(SAVE_USER);
export const setProfile = createAlertAction(SET_PROFILE);
export const sendBugReport = createAlertAction(SEND_BUG_REPORT);
