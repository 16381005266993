import styled from 'styled-components';

import searchIcon from 'assets/svg-icons/search.svg';

export const Container = styled.label`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

export const Label = styled.span`
  font: var(--font16);
  color: var(--color-gray90);
`;

const getBackground = ({ type }) =>
  type === 'search'
    ? `
      background-image: url(${searchIcon});
      background-position: left;
      background-repeat: no-repeat;
      background-size: 20px;
      padding-left: 25px; 
    `
    : '';

export const Input = styled.input`
  width: 100%;
  height: 36px;
  border-bottom: 1px solid;
  border-color: var(--color-gray80);
  border-radius: 0;
  ${getBackground}
  font: var(--font16);
  color: var(--color-gray80);
  transition-property: border-color, color;
  transition-duration: 0.3s;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  &:focus {
    border-color: var(--color-blue80);
    color: var(--color-text100);
  }
  &:disabled {
    opacity: 1;
    cursor: not-allowed;
  }
`;

export const Message = styled.span`
  font: var(--font14M);
  color: var(--color-error);
`;
