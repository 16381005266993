import React, { forwardRef, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Container, Label, TA, Message } from './styles';

const Textarea = forwardRef(
  (
    {
      label = null,
      value,
      onChange = () => {},
      onChangText = () => {},
      disabled = false,
      error = null,
      className = null,
      ...rest
    },
    ref
  ) => {
    const handleChange = useCallback(
      (e) => {
        onChange(e);
        onChangText(e.currentTarget.value);
      },
      [onChangText, onChange]
    );

    return (
      <Container className={className}>
        {label && <Label>{label}</Label>}
        <TA ref={ref} value={value} onChange={handleChange} disabled={disabled} {...rest} />
        {!disabled && error && <Message>{error}</Message>}
      </Container>
    );
  }
);

Textarea.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onChangText: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  className: PropTypes.string,
};

export default Textarea;
