export const FEATURE_NAME = 'session';

export const AUTH = 'auth';
export const LOGGED_IN = 'loggedIn';
export const USER = 'user';
export const STATUS = 'status';

export const DEGREE = 'degree';
export const FIRST_NAME = 'firstname';
export const LAST_NAME = 'lastname';
export const STUDY_YEAR = 'studyYear';
export const AIM = 'aim';
export const CONFIDENCE = 'confidence';
export const LANGUAGE = 'language';
export const MESSAGE = 'message';
export const PAGE_URL = 'url';
