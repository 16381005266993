import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Spinner from 'components/Spinner';

import { Btn } from './styles';

const Button = ({ type = 'button', size = 'M', isLoading = false, disabled = false, className = null, children, ...rest }) => {
  const [height, spinnerSize] = useMemo(() => {
    const px = (size === 'M' && 44) || (size === 'L' && 56) || (size === 'S' && 32) || 0;

    return [px ? `${px}px` : 'auto', px ? px - 10 : 22];
  }, [size]);

  return (
    <Btn type={type} className={className} $height={height} disabled={isLoading || disabled} {...rest}>
      {isLoading ? <Spinner size={spinnerSize} color="var(--color-white)" /> : children}
    </Btn>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'submit']),
  size: PropTypes.oneOf(['L', 'M', 'S']),
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default Button;
