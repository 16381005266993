import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import { ReactComponent as LogoIcon } from 'assets/svg-icons/logo.svg';
import LangSelect from 'components/LangSelect';
import Support from 'components/Support';

import { GlobalStyles, Header, HomeLink, Content, Footer, Text, A } from './styles';

const SkyLayout = () => {
  const year = useMemo(() => new Date().getFullYear(), []);

  return (
    <>
      <GlobalStyles />
      <Header>
        <HomeLink to="/">
          <LogoIcon />
        </HomeLink>
        <LangSelect />
        <Support />
      </Header>
      <Content>
        <Outlet />
      </Content>
      <Footer>
        <Text>
          © {year}{' '}
          <A href="https://medignition.com/" target="_blank" rel="noreferrer">
            medignition AG
          </A>
        </Text>
      </Footer>
    </>
  );
};

export default SkyLayout;
