import { takeLatest, takeLeading, call, put, select } from 'redux-saga/effects';
import sha256 from 'crypto-js/sha256';
import { path, omit, pick } from 'ramda';

import { withAlert, applyCancelToken } from 'store/alerts';
import api from 'api';
import i18n from 'locale';

import { authenticate, updateUser } from './actions';
import { getUser } from './selectors';
import { LOGIN, GET_CODE, RESTORE, SAVE_USER, SET_PROFILE, SEND_BUG_REPORT } from './types';
import { PASSWORD, TOKEN, ID, USER, AIM, CONFIDENCE, STATUS, DEGREE, FIRST_NAME, LAST_NAME, STUDY_YEAR, LANGUAGE } from '.';

const baseURL = process.env.REACT_APP_URL;

function* login({ payload }) {
  const data = yield call(api.post, '/login', { ...payload, [PASSWORD]: sha256(payload[PASSWORD]).toString() }, { baseURL });

  if (!data?.error) yield put(authenticate(data));

  return { success: data };
}

function* getCode({ payload }) {
  return {
    success: yield call(api.post, '/authorize', payload, { baseURL }),
  };
}

function* restore(action) {
  const data = yield call(api.get, '/session', { baseURL, ...applyCancelToken(action) });

  yield put(authenticate({ ...data, [TOKEN]: data[TOKEN] || path([process.env.REACT_APP_TOKEN_STORAGE, TOKEN], window) }));
}

function* saveUser({ payload, ...rest }) {
  const user = yield select(getUser);
  const data = yield call(api.patch, `/users/${payload[ID]}`, omit([ID], payload, applyCancelToken(rest)));

  if (user[LANGUAGE] !== data[LANGUAGE] && data[LANGUAGE]) i18n.changeLanguage(data[LANGUAGE]);

  yield put(updateUser(data));
}

function* setProfile({ payload }) {
  yield call(api.post, '/profiles', pick([USER, AIM, CONFIDENCE, STUDY_YEAR, DEGREE], payload));
  yield saveUser({ payload: { [ID]: payload[USER], [STATUS]: 1, ...pick([FIRST_NAME, LAST_NAME], payload) } });

  const user = yield select(getUser);

  if (user) yield put(authenticate(user));
}

function* sendBugReport({ payload }) {
  yield call(api.post, '/issues', payload);
}

export default function* watchSession() {
  yield takeLeading(LOGIN, withAlert(login));
  yield takeLeading(GET_CODE, withAlert(getCode));
  yield takeLatest(RESTORE, withAlert(restore));
  yield takeLatest(SAVE_USER, withAlert(saveUser));
  yield takeLeading(SET_PROFILE, withAlert(setProfile));
  yield takeLeading(SEND_BUG_REPORT, withAlert(sendBugReport));
}
