import { styled } from 'styled-components';

export const Btn = styled.button`
  height: ${({ $height }) => $height};
  width: 100%;
  font: var(--font14M);
  color: var(--color-white);
  border-radius: var(--border-radius);
  background-color: var(--color-blue80);
  transition: background-color 0.3s;
  &:active {
    background-color: var(--color-blue60);
  }
  &:disabled {
    background-color: var(--color-gray70);
  }
  @media (hover) {
    &:hover:enabled {
      background-color: var(--color-blue60);
    }
  }
`;
