import React from 'react';
import PropTypes from 'prop-types';

import { Text } from './styles';

const Message = ({ error = false, className = null, children }) => (
  <Text $isRed={error} className={className}>
    {children}
  </Text>
);

Message.propTypes = {
  error: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Message;
