import React, { forwardRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Container, Label, StyledSelect, Message } from './styles';

const Select = forwardRef(
  (
    {
      label = null,
      options = [],
      value = undefined,
      onChange = () => {},
      onSelect = () => {},
      placeholder,
      disabled = false,
      error = null,
      className = null,
      ...rest
    },
    ref
  ) => {
    const { t } = useTranslation('common');
    const title = placeholder || t('Select');
    const handleChange = useCallback(
      (e) => {
        onChange(e);
        onSelect(e.currentTarget.value);
      },
      [onSelect, onChange]
    );

    return (
      <Container className={className}>
        {label && <Label>{label}</Label>}
        <StyledSelect ref={ref} value={value} disabled={disabled} onChange={handleChange} {...rest}>
          <option value="" label={title} disabled>
            {title}
          </option>
          {options.map(({ id, label: itemLabel, disabled: itemDisabled }) => (
            <option key={id} value={id} label={itemLabel} disabled={itemDisabled}>
              {itemLabel}
            </option>
          ))}
        </StyledSelect>
        {!disabled && error && <Message>{error}</Message>}
      </Container>
    );
  }
);

Select.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    })
  ),
  value: PropTypes.string,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  className: PropTypes.string,
};

export default Select;
